import React from "react";

import Layout from "../components/organisms/Layout/Layout";
import PageWidth from "../components/organisms/PageWidth/PageWidth";
import SEO from "../components/atoms/SEO/SEO";
import SmartLink from "../components/atoms/SmartLink/SmartLink";

const Coaching = () => (
  <Layout>
    <SEO
      title="Honig aus dem Hopfengarten"
      description="Die Bienen aus der Hopfenbreite fliegen in einem Umkreis von bis zu etwa 3.000 Metern."
      meta={[
        {
          "http-equiv": "refresh",
          content: "5; url=https://www.stadthonig.shop",
        },
      ]}
    />
    <PageWidth isContent={true}>
      <h1>Honig aus dem Hopfengarten</h1>
      <p>
        Informationen zur Honigsorte, zu den Bienen und viele Infos mehr findest
        Du auf meiner neuen Honig-Homepage
        <SmartLink to="https://www.stadthonig.shop" inNewWindow={true}>
          stadthonig.shop
        </SmartLink>
      </p>
    </PageWidth>
  </Layout>
);

export default Coaching;
